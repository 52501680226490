@use './colors' as *;

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-size: 1.6rem;
	overflow-x: hidden;
}
.container {
	margin: 0 auto;
}
.section {
	position: relative;
	padding: 1em;
}

.waves {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-bottom: -1em;
	z-index: 100;
}
// ----------------
//     Nav
// ----------------
.nav {
	position: sticky;
	top: 0;
	// margin: 1rem;
	box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
	z-index: 1003;
	ul li {
		list-style: none;
	}
	&__main {
		display: flex;
		justify-content: space-between;
		padding: 0.4em;
		width: 100vw;
		background-color: #eeeded;
		z-index: 10001;
	}
	&__img {
		height: 5rem;
	}
	&__left {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__txt {
		margin-left: 1rem;
		font-weight: bold;
		font-size: 1.4rem;
        cursor: pointer;
	}
	&__right {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__el {
		display: none;
	}
	&__btn {
		display: none;
		padding: 0.5em 1em;
		border: none;
		background-color: $lighter-secondary-blue;
		border-radius: 50px;
		box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
		color: #000;
		font-weight: bold;
		z-index: 10000;
		&-txt {
			text-transform: uppercase;
			border: none;
			background-color: $lighter-secondary-blue;
			color: $dark-blue;
			font-weight: bold;
            cursor: pointer;
		}
	}
	&__icon {
		padding: 0.5em;
		font-size: 2rem;
	}
	&__items {
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-left: 0;
		padding: 1em;
		padding-top: 5em;
		width: 100vw;
		transform: translate(0%, -120%);
		background-color: #ffffff4b;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		color: $dark-blue;
		text-decoration: none;
		transition: transform 0.3s;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		z-index: -1;
		&--active {
			transform: translate(0%, 0%);
		}
	}
	&__item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding: 0.5em 2em;
		font-size: 1.6rem;
		text-decoration: none;
		color: $dark-blue;
		text-align: center;
		&-txt {
			background-color: $lighter-secondary-blue;
			border: none;
			color: $dark-blue;
		}
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 3px;
			height: 100%;
			transform: scaleY(0);
			background-color: $dark-blue;
			transition: transform 0.3s;
			content: '';
		}
		&:hover::before {
			transform: scaleY(1);
		}
		&-btn {
			margin: 0 auto;
			margin-top: 1em;
			margin-bottom: 1em;
			padding: 0.5em 1em;
			border: none;
			background-color: $lighter-secondary-blue;
			border-radius: 50px;
			box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
			color: $dark-blue;
			z-index: 100;
		}
	}
}

.burger-btn {
	position: fixed;
	top: 10px;
	right: 15px;
	padding: 1em;
	background: none;
	border: 1px solid transparent;
	cursor: pointer;
	overflow: hidden;
	z-index: 10000;
	&:focus {
		outline: none;
		border: 1px solid rgba(255, 255, 255, 0.5);
		border-radius: 8px;
	}

	&__box {
		position: relative;
		width: 30px;
		height: 20px;
	}
	&__bars,
	&__bars::after,
	&__bars::before {
		position: absolute;
		right: 0;
		height: 3px;
		border-radius: 8px;
		content: '';
		background-color: $dark-blue;
		transition: background-color 0.3s, transform 0.3s;
	}
	&__bars {
		width: 100%;
		top: 50%;

		&::after {
			transform: translateY(-7px);
			width: 100%;
			transition-delay: 0.05s;
		}

		&::before {
			transform: translateY(7px);
			width: 100%;
			transition-delay: 0.05s;
		}
	}
}
.burger-btn--open .burger-btn__bars {
	transform: translateX(-60px);
	background: transparent;
}
.burger-btn--open .burger-btn__bars::before {
	transform: rotate(45deg) translate(42.5px, -42.5px);
}
.burger-btn--open .burger-btn__bars::after {
	transform: rotate(-45deg) translate(42.5px, 42.5px);
}
.burger-btn--open .burger-btn:hover {
	.burger-btn__bars::after,
	.burger-btn__bars::before {
		background-color: #fff;
	}
}
.burger-btn--open .burger-btn:hover {
	.burger-btn:hover {
		background-color: transparent;
	}
}
@keyframes navItemsAnimation {
	from {
		transform: translateY(-200%);
	}
	to {
		transform: translateY(0%);
	}
}
.nav-items-animation {
	animation: navItemsAnimation 0.5s both;
}
// ----------------
//     Header
// ----------------

.header {
	position: relative;
	z-index: 100;
	&__img {
		position: relative;
		width: 100vw;
		height: 50vh;
		background-image: url('/dist/img/AdobeStock_203863013-min.jpg');
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&__btn {
		position: absolute;
		top: 27vh;
		left: 50%;
		transform: translateX(-50%);
		padding: 1em 2em;
		border-radius: 50px;
		border: none;
		box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
		background-color: $lighter-secondary-blue;
		z-index: 101;
        cursor: pointer;
	}
	&__txt {
		background-color: $lighter-secondary-blue;
		border: none;
		color: $dark-blue;
		font-size: 1.4rem;
		text-transform: uppercase;
		font-weight: bold;
        cursor: pointer;
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #0000004f;
		z-index: 1;
	}
	&__title {
		position: absolute;
		top: 10vh;
		left: 0;
		width: 100vw;
		// margin-top: 2em;
		padding: 0.5em;
		margin-bottom: 2.5em;
		font-size: 1.6rem;
		font-weight: normal;
		color: #ffffff;
		text-align: center;
		text-transform: uppercase;
		z-index: 100;
	}
	&__slogan {
		position: absolute;
		top: 15vh;
		left: 0;
		width: 100vw;
		padding: 0.5em;
		font-size: 2.6rem;
		font-weight: bold;
		color: #ffffff;
		text-align: center;
		z-index: 100;
	}
}

// ----------------
//     Footer
// ----------------

.footer {
	width: 100vw;
	background-color: $dark-blue;
	color: #fff;
	.wrapper {
		padding: 6em 2em 2em;
	}
	&__box {
		font-size: 1.4rem;

		&-title {
			margin-bottom: 1em;
			font-size: 3rem;
			text-transform: uppercase;
		}
		// &-list {
		//     list-style: none;
		// }
		&-item {
			margin-bottom: 1em;
			// text-align: center;
			a {
				color: #fff;
				text-decoration: none;
                cursor: pointer;
			}
		}
		&-social {
			margin: 2em 0 1em;
		}
		&-desktop {
			display: none;
		}
		&-link {
			padding: 0 0.3em;
			color: #fff;
			font-size: 3.6rem;
			text-decoration: none;
			transition: color 0.3s;
			&:first-child {
				padding: 0;
			}

			&:hover {
				color: #efefef;
			}
		}
	}

	&__bottom-text {
		padding: 2em;
		text-align: center;
	}
}
.callus {
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: cover;
}

.about {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: contain;
	background-repeat: repeat;
	background-position: left top;
	&__title {
		margin-top: 2em;
		margin-bottom: 2.5em;
		font-size: 2.6rem;
		font-weight: bold;
		color: $dark-blue;
		text-align: center;
		text-transform: uppercase;
	}
	&__boxes {
		display: flex;
		align-content: center;
		justify-content: space-evenly;
		// flex-direction: column;
		flex-wrap: wrap;
	}
	&__box {
		position: relative;
		padding: 1em;
		padding-bottom: 2em;
		margin-bottom: 3.5em;
		// height: 300px;
		width: 300px;
		background-color: $lighter-main-blue;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		border-radius: 20px;
        &-txt{
            cursor: pointer;
        }
		&-title {
			margin-top: 2em;
			margin-bottom: 1em;
			color: $dark-blue;
			text-align: center;
		}
		&-circle {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 70px;
			height: 70px;
			border-radius: 50%;
			box-shadow: 0px 0px 17px -7px rgba(66, 68, 90, 1);
			background-color: #efefef;
		}
		&-icon {
			top: 0;
			left: 0;
			width: 70px;
			&-sur {
				transform: translate(50%, 30%);
			}
		}
	}
	&__btn {
		margin: 0 auto;
		margin-bottom: 4em;
		padding: 0.5em 2em;
		border: none;
		background-color: $lighter-secondary-blue;
		border-radius: 50px;
		box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
		color: $dark-blue;
		font-weight: bold;
		z-index: 101;
        cursor: pointer;
	}
}

.contact {
	position: relative;
	background-color: $main-blue;
	background-image: url('/dist/img/mobile-route-6.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom;
	&__title {
		margin-top: 2em;
		margin-bottom: 2.5em;
		font-size: 2.6rem;
		font-weight: bold;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
	}

	&__boxes {
		display: flex;
		align-content: center;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}
	&__box {
		position: relative;
		width: 320px;
		margin-bottom: 4em;
		background-color: $lighter-main-blue;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		border-radius: 20px;
        z-index: 101;
		&-title {
			margin-top: 2.5em;
			margin-bottom: 1em;
			text-transform: uppercase;
			color: $dark-blue;
			text-decoration: none;
			text-align: center;
		}
		&-num {
			margin-bottom: 1em;
			font-weight: bold;
			color: #535151;
		}
		&-circle {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 70px;
			height: 70px;
			border-radius: 50%;
			box-shadow: 0px 0px 17px -7px rgba(66, 68, 90, 1);
			background-color: #efefef;
		}
		&-icon {
			color: $secondary-blue;
			font-size: 40px;
			text-align: center;
			line-height: 70px;
			transform: translate(40%, 30%);
		}
	}
}

.whatnext {
	position: relative;
	background-image: url('/dist/img/mobile-route-ap-8.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top;
	&__right {
		display: flex;
		justify-content: end;
	}
	&__boxes {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
        margin-bottom: 16em;
	}
	&__box {
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 1em;
		width: 300px;
		margin-bottom: 4em;
		background-color: $lighter-main-blue;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		border-radius: 20px;
		&-title {
			margin-top: 2.5em;
			margin-bottom: 1em;
			text-transform: uppercase;
			color: $dark-blue;
			text-decoration: none;
			text-align: center;
		}
		&-circle {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 70px;
			height: 70px;
			border-radius: 50%;
			box-shadow: 0px 0px 17px -7px rgba(66, 68, 90, 1);
			background-color: #efefef;
		}
		&-icon {
			color: $dark-blue;
			font-size: 40px;
			text-align: center;
			line-height: 70px;
			transform: translate(30%, 30%);
			&-location {
				transform: translate(70%, 30%);
			}
		}
		&-p {
			margin-bottom: -1em;
			text-align: center;
		}
		&-txt {
			border: none;
			background-color: $lighter-secondary-blue;
			color: $dark-blue;
			font-weight: bold;
            cursor: pointer;
		}
		&-btn {
			margin: 0 auto;
			margin-top: 3em;
			margin-bottom: 1em;
			padding: 0.5em 1em;
			border: none;
			background-color: $lighter-secondary-blue;
			border-radius: 50px;
			box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
			z-index: 101;
		}
		&-right {
			margin-left: 3.5em;
		}
	}
	&__title {
		margin-top: 2em;
		margin-bottom: 2.5em;
		font-size: 2.6rem;
		font-weight: bold;
		color: $dark-blue;
		text-align: center;
		text-transform: uppercase;
	}
}

// ----------------
//    Usługi
// ----------------

.first__row {
	display: none;
}
.second__row {
	display: none;
}

.offer {
	position: relative;
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: contain;
	background-repeat: repeat;
	&__title {
		margin-top: 2em;
		margin-bottom: 2.5em;
		font-size: 2.6rem;
		font-weight: bold;
		color: $dark-blue;
		text-align: center;
		text-transform: uppercase;
	}
	&__boxes {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		margin-bottom: 1em;
		padding-bottom: 2em;
	}
	&__box {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 1em;
		margin-bottom: 2em;
		width: 150px;
		height: 150px;
		border: none;
		background-color: #efefef;
		border-radius: 20px;
        text-decoration: none;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		cursor: pointer;
        z-index: 101;
		&:last-child {
			padding-bottom: 6em;
		}
		&-title {
			margin-top: 7.5em;
			margin-left: auto;
			margin-right: auto;
            padding-bottom: 1em;
			font-size: 1.4rem;
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
			color: $dark-blue;
			&--zw {
				margin-top: 7em;
			}
		}
		&-img {
			position: absolute;
			width: 140px;
			top: 0;
			left: 0;
		}
	}
}
.aboutus {
	position: relative;
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: contain;
	background-repeat: repeat;
	&__boxes {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
        margin-bottom: 1em;
	}
	&__box {
		position: relative;
		padding: 1em 2em;
		margin-right: 1em;
		margin-left: 1em;
		margin-bottom: 3em;
		width: 330px;
		height: 400px;
		background-color: #eceaea;
		border-radius: 20px;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		&-description1{
			text-align: justify;

		}

		&-circle {
			position: absolute;
			top: 0;
			left: 50%;
			width: 75px;
			height: 75px;
			border-radius: 50%;
			background-color: #fff;
			transform: translate(-50%, -50%);
			font-size: 4rem;
			box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 0.659);
		}
		&-name {
			margin-top: 1.5em;
			margin-bottom: 1em;
			color: $dark-blue;
			font-size: 2rem;
			text-align: center;
		}
        &-title{
            color: #7e7e7e;
            font-size: 1.6rem;

        }
        &-clinic{
            padding-top: 4em;
            width: 400px;
            height: min-content;
        }
	}

	&-icon {
		transform: translate(45%, 30%);
		color: $dark-blue;
	}

	&__photos {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 2em;
	}
	&__photo {
        width: 350px;
		margin-bottom: 1.5em;
		margin-right: 1em;
		margin-left: 1em;
		border-radius: 20px;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
        z-index: 101;
	}
}
.singleoffer {
	position: relative;
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: contain;
	background-repeat: repeat;
    &__title{
        color: $dark-blue;
    }
	&__boxes {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__list {
		margin-top: 2em;
		padding: 1em;
		list-style-type: circle;
	}
	&__item {
		padding: 0.5em;
		padding-top: 0em;
		text-transform: uppercase;
		color: $dark-blue;
		// font-weight: bold;
		font-size: 1.8rem;
	}
	&__box {
		position: relative;
		padding: 1em 2em;
		margin-bottom: 6em;
		width: 350px;
		background-color: #eceaea;
		border-radius: 20px;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
        z-index: 101;
		&-circle {
			position: absolute;
			top: 0;
			left: 50%;
			width: 100px;
			height: 100px;
			background-color: #f6f3f3;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 0.659);
		}
		&-img {
			width: 100px;
		}
	}
}
.tutorial {
	position: relative;
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: contain;
	background-repeat: repeat;
	&__boxes {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 6em;
	}
	&__box {
		position: relative;
		padding: 1em 2em;
		margin-right: 1em;
		margin-left: 1em;
		margin-bottom: 3em;
		width: 330px;
		background-color: #eceaea;
		border-radius: 20px;
		box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 1);
		&-circle {
			position: absolute;
			top: 0;
			left: 50%;
			width: 75px;
			height: 75px;
			border-radius: 50%;
			background-color: #fff;
			transform: translate(-50%, -50%);
			font-size: 4rem;
			box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 0.659);
		}
		&-name {
			margin-top: 2em;
			margin-bottom: 1em;
			color: $dark-blue;
			font-size: 2rem;
			text-align: center;
		}
	}
	&-icon {
		transform: translate(45%, 30%);
		color: $dark-blue;
	}
	&__list {
		list-style-type: circle;
	}
	&__item {
		padding: 0.5em;
		padding-top: 0em;
	}
}

.reservations {
	background-image: url('/dist/img/mobile-paws-bg-5.png');
	background-size: contain;
	background-repeat: repeat;
}

.hours {
	display: flex;
	justify-content: center;
	align-items: center;
}
.harmonogram {
	border-collapse: collapse;
	margin: 2.5em 0;
	font-size: 0.9em;
	min-height: 410px;
	border-radius: 5px 5px 0 0;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.481);
	overflow: hidden;
    z-index: 101;
}
.harmonogram thead tr {
	background-color: $lighter-main-blue;
	color: $dark-blue;
	text-align: left;
	font-weight: bold;
}
.harmonogram th,
.harmonogram td {
	padding: 0.3em;
}
.harmonogram tbody tr {
	border-bottom: 1px solid #ddd;
}
.harmonogram tbody tr:nth-of-type(even) {
	background-color: #f3f3f3;
}
.harmonogram tbody tr:nth-of-type(odd) {
	background-color: #fff;
}
.harmonogram tbody tr:last-of-type {
	border-bottom: 3px solid $lighter-main-blue;
}
.page__title {
	margin-top: 2em;
	margin-bottom: 2.5em;
	font-size: 2.6rem;
	font-weight: bold;
	color: $dark-blue;
	text-align: center;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.header{
        &__btn{
            top: 32vh;
            padding: 1.1em 2.2em;
        }
    }
    .page__title{
        font-size: 3.6rem;
    }
    .tutorial {
		&__box {
			height: 730px;
		}
	}

	.singleoffer {
		background-image: url('/dist/img/desktop-paws-bg-4.png');
		background-size: cover;
		&__box {
			width: 500px;
			margin-bottom: 8em;
		}
	}

	.header {
		&__title {
			font-size: 2.2rem;
		}
		&__slogan {
			font-size: 3.6rem;
		}
		&__img {
			height: 60vh;
		}
	}
	.burger-btn {
		right: 25px;
	}
	.about {
		&__btn {
			padding: 1em 2em;
			margin-bottom: 8em;
		}
	}
	.contact {
		&__title {
			margin-top: 1em;
		}
		&__boxes {
			margin-bottom: 2em;
		}
	}
	.whatnext {
		&__box {
			width: 400px;
			&-p {
				padding: 0 2em;
			}
		}
	}
}

@media (min-width: 830px) {
    .offer__boxes-row {
		margin: 0 auto;
		width: 800px;
	}
	.first__row {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
	}
	.second__row {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
		margin-bottom: 8em;
	}

	.offer {
		background-image: url('/dist/img/desktop-paws-bg-4.png');
		background-repeat: no-repeat;
		background-size: cover;
		&__boxes {
			display: none;
		}
	}
	.aboutus {
		&__photos {
			margin-bottom: 8em;
		}
	}
}

// ----------------
//    Komputery
// ----------------

@media (min-width: 992px) {
	.singleoffer {
		&__box {
			width: 600px;
			margin-bottom: 10em;
		}
	}
	.callus {
		background-image: url('/dist/img/desktop-paws-bg-4.png');
	}
	.footer {
		&__boxes {
			display: flex;
			justify-content: space-between;
		}
		&__box {
			margin: 0 2em;
			width: 25%;
			&-mobile {
				display: none;
			}
			&-desktop {
				display: block;
			}
			&:first-child {
				width: 50%;
			}
		}
	}
	.header {
		&__img {
			height: 75vh;
			background-size: cover;
		}
		&__slogan {
			top: 25vh;
			font-size: 4.2rem;
		}
		&__title {
			font-size: 3.6rem;
			top: 20vh;
		}
		&__btn {
			top: 43vh;
		}
	}
	.second__row {
		margin-bottom: 10em;
	}
	.aboutus {
		&__photo {
			width: 400px;
		}
		&__photos {
			display: grid;
			grid-template-columns: repeat(2, 0fr);
			margin-bottom: 9em;
		}
		&__box{
			width: 600px;
		}
	}
	.nav {
		&__img {
			margin-left: 1em;
			transform: translate(0%);
		}
		&__right {
			display: flex;
		}
		&__el {
			display: inline;
			margin-right: 1em;
			font-size: 1.4rem;
			&:last-child {
				margin-right: 2em;
			}
			a {
				color: $dark-blue;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: bold;
			}
		}
		&__li {
			color: $dark-blue;
			text-decoration: none;
		}
		&__btn {
			display: inline-block;
			margin-right: 2.5em;
		}
	}
	.burger-btn {
		display: none;
	}
	.waves {
		margin-bottom: -1.1em;
	}
	.about {
		background-image: url('/dist/img/desktop-paws-bg-4.png');
		background-size: cover;
		&__title {
			font-size: 3.6rem;
		}
		&__box {
			margin: 1em;
			width: 210px;
		}
		&__boxes {
			margin-bottom: 2em;
		}
		&__btn {
			font-size: 1.3rem;
			padding: 1em 2em;
			margin-bottom: 10em;
		}
	}
	.contact {
		background-image: url('/dist/img/desktop-route-7.png');
		&__title {
			margin-top: 0em;
			font-size: 3.6rem;
		}
		&__box {
			margin-bottom: 8em;
		}
	}
	.whatnext {
		background-image: url('/dist/img/desktop-wn-6.png');
		&__title {
			font-size: 3.6rem;
		}
		&__boxes {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&__box {
			margin-left: 6em;
			width: 400px;
			&-right {
				margin-right: 2em;
				justify-self: flex-end;
			}
			&-btn {
				margin-bottom: 2em;
				padding: 1em 2em;
			}
		}
	}
	.reservations {
		background-image: url('/dist/img/desktop-paws-bg-4.png');
		background-size: cover;
		background-repeat: repeat;
	}
	.hours {
		margin-bottom: 12em;
		background-image: url('/dist/img/desktop-paws-bg-4.png');
		background-size: cover;
		background-repeat: repeat;
	}
	.harmonogram {
		border-collapse: collapse;
		margin: 2.5em 1em;
		font-size: 1em;
		min-height: 400px;
		border-radius: 5px 5px 0 0;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.481);
		overflow: hidden;
	}
	.harmonogram thead tr {
		background-color: $lighter-main-blue;
		color: $dark-blue;
		text-align: left;
		font-weight: bold;
	}
	.harmonogram th,
	.harmonogram td {
		padding: 1em;
	}
}

@media (min-width: 1200px) {
	.singleoffer {
		&__box {
			width: 800px;
			margin-bottom: 16em;
		}
	}
	.tutorial {
		&__boxes {
			margin-bottom: 12em;
		}
	}

	.second__row {
		margin-bottom: 16em;
	}
	.aboutus {
		&__photos {
			margin-bottom: 12em;
		}
	}
	.page__title {
		font-size: 3.6rem;
	}
	.nav {
		&__el {
			font-size: 1.6rem;
		}
	}
	.about {
		&__box {
			width: 250px;
		}
		&__boxes {
			margin-bottom: 2em;
		}
		&__btn {
			margin-bottom: 20em;
		}
	}
	.header {
		&__img {
			height: 80vh;
		}
	}
	.whatnext {
		&__box {
			margin-bottom: 6em;
			&-right {
				margin-right: 10em;
			}
		}
	}
	.contact {
		&__boxes {
			margin-bottom: 10em;
		}
	}
}
