*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  overflow-x: hidden;
}

.container {
  margin: 0 auto;
}

.section {
  position: relative;
  padding: 1em;
}

.waves {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -1em;
  z-index: 100;
}

.nav {
  position: sticky;
  top: 0;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  z-index: 1003;
}
.nav ul li {
  list-style: none;
}
.nav__main {
  display: flex;
  justify-content: space-between;
  padding: 0.4em;
  width: 100vw;
  background-color: #eeeded;
  z-index: 10001;
}
.nav__img {
  height: 5rem;
}
.nav__left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav__txt {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
}
.nav__right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav__el {
  display: none;
}
.nav__btn {
  display: none;
  padding: 0.5em 1em;
  border: none;
  background-color: #bee9e8;
  border-radius: 50px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
  color: #000;
  font-weight: bold;
  z-index: 10000;
}
.nav__btn-txt {
  text-transform: uppercase;
  border: none;
  background-color: #bee9e8;
  color: #1b4965;
  font-weight: bold;
  cursor: pointer;
}
.nav__icon {
  padding: 0.5em;
  font-size: 2rem;
}
.nav__items {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 0;
  padding: 1em;
  padding-top: 5em;
  width: 100vw;
  transform: translate(0%, -120%);
  background-color: rgba(255, 255, 255, 0.2941176471);
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  color: #1b4965;
  text-decoration: none;
  transition: transform 0.3s;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: -1;
}
.nav__items--active {
  transform: translate(0%, 0%);
}
.nav__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0.5em 2em;
  font-size: 1.6rem;
  text-decoration: none;
  color: #1b4965;
  text-align: center;
}
.nav__item-txt {
  background-color: #bee9e8;
  border: none;
  color: #1b4965;
}
.nav__item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  transform: scaleY(0);
  background-color: #1b4965;
  transition: transform 0.3s;
  content: "";
}
.nav__item:hover::before {
  transform: scaleY(1);
}
.nav__item-btn {
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  border: none;
  background-color: #bee9e8;
  border-radius: 50px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
  color: #1b4965;
  z-index: 100;
}

.burger-btn {
  position: fixed;
  top: 10px;
  right: 15px;
  padding: 1em;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  overflow: hidden;
  z-index: 10000;
}
.burger-btn:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}
.burger-btn__box {
  position: relative;
  width: 30px;
  height: 20px;
}
.burger-btn__bars, .burger-btn__bars::after, .burger-btn__bars::before {
  position: absolute;
  right: 0;
  height: 3px;
  border-radius: 8px;
  content: "";
  background-color: #1b4965;
  transition: background-color 0.3s, transform 0.3s;
}
.burger-btn__bars {
  width: 100%;
  top: 50%;
}
.burger-btn__bars::after {
  transform: translateY(-7px);
  width: 100%;
  transition-delay: 0.05s;
}
.burger-btn__bars::before {
  transform: translateY(7px);
  width: 100%;
  transition-delay: 0.05s;
}

.burger-btn--open .burger-btn__bars {
  transform: translateX(-60px);
  background: transparent;
}

.burger-btn--open .burger-btn__bars::before {
  transform: rotate(45deg) translate(42.5px, -42.5px);
}

.burger-btn--open .burger-btn__bars::after {
  transform: rotate(-45deg) translate(42.5px, 42.5px);
}

.burger-btn--open .burger-btn:hover .burger-btn__bars::after,
.burger-btn--open .burger-btn:hover .burger-btn__bars::before {
  background-color: #fff;
}

.burger-btn--open .burger-btn:hover .burger-btn:hover {
  background-color: transparent;
}

@keyframes navItemsAnimation {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
}
.nav-items-animation {
  animation: navItemsAnimation 0.5s both;
}

.header {
  position: relative;
  z-index: 100;
}
.header__img {
  position: relative;
  width: 100vw;
  height: 50vh;
  background-image: url("/dist/img/AdobeStock_203863013-min.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header__btn {
  position: absolute;
  top: 27vh;
  left: 50%;
  transform: translateX(-50%);
  padding: 1em 2em;
  border-radius: 50px;
  border: none;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
  background-color: #bee9e8;
  z-index: 101;
  cursor: pointer;
}
.header__txt {
  background-color: #bee9e8;
  border: none;
  color: #1b4965;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3098039216);
  z-index: 1;
}
.header__title {
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100vw;
  padding: 0.5em;
  margin-bottom: 2.5em;
  font-size: 1.6rem;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  z-index: 100;
}
.header__slogan {
  position: absolute;
  top: 15vh;
  left: 0;
  width: 100vw;
  padding: 0.5em;
  font-size: 2.6rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  z-index: 100;
}

.footer {
  width: 100vw;
  background-color: #1b4965;
  color: #fff;
}
.footer .wrapper {
  padding: 6em 2em 2em;
}
.footer__box {
  font-size: 1.4rem;
}
.footer__box-title {
  margin-bottom: 1em;
  font-size: 3rem;
  text-transform: uppercase;
}
.footer__box-item {
  margin-bottom: 1em;
}
.footer__box-item a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.footer__box-social {
  margin: 2em 0 1em;
}
.footer__box-desktop {
  display: none;
}
.footer__box-link {
  padding: 0 0.3em;
  color: #fff;
  font-size: 3.6rem;
  text-decoration: none;
  transition: color 0.3s;
}
.footer__box-link:first-child {
  padding: 0;
}
.footer__box-link:hover {
  color: #efefef;
}
.footer__bottom-text {
  padding: 2em;
  text-align: center;
}

.callus {
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: cover;
}

.about {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: contain;
  background-repeat: repeat;
  background-position: left top;
}
.about__title {
  margin-top: 2em;
  margin-bottom: 2.5em;
  font-size: 2.6rem;
  font-weight: bold;
  color: #1b4965;
  text-align: center;
  text-transform: uppercase;
}
.about__boxes {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.about__box {
  position: relative;
  padding: 1em;
  padding-bottom: 2em;
  margin-bottom: 3.5em;
  width: 300px;
  background-color: #cae9ff;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  border-radius: 20px;
}
.about__box-txt {
  cursor: pointer;
}
.about__box-title {
  margin-top: 2em;
  margin-bottom: 1em;
  color: #1b4965;
  text-align: center;
}
.about__box-circle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 17px -7px rgb(66, 68, 90);
  background-color: #efefef;
}
.about__box-icon {
  top: 0;
  left: 0;
  width: 70px;
}
.about__box-icon-sur {
  transform: translate(50%, 30%);
}
.about__btn {
  margin: 0 auto;
  margin-bottom: 4em;
  padding: 0.5em 2em;
  border: none;
  background-color: #bee9e8;
  border-radius: 50px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
  color: #1b4965;
  font-weight: bold;
  z-index: 101;
  cursor: pointer;
}

.contact {
  position: relative;
  background-color: #5fa8d3;
  background-image: url("/dist/img/mobile-route-6.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.contact__title {
  margin-top: 2em;
  margin-bottom: 2.5em;
  font-size: 2.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.contact__boxes {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.contact__box {
  position: relative;
  width: 320px;
  margin-bottom: 4em;
  background-color: #cae9ff;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  border-radius: 20px;
  z-index: 101;
}
.contact__box-title {
  margin-top: 2.5em;
  margin-bottom: 1em;
  text-transform: uppercase;
  color: #1b4965;
  text-decoration: none;
  text-align: center;
}
.contact__box-num {
  margin-bottom: 1em;
  font-weight: bold;
  color: #535151;
}
.contact__box-circle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 17px -7px rgb(66, 68, 90);
  background-color: #efefef;
}
.contact__box-icon {
  color: #62b6cb;
  font-size: 40px;
  text-align: center;
  line-height: 70px;
  transform: translate(40%, 30%);
}

.whatnext {
  position: relative;
  background-image: url("/dist/img/mobile-route-ap-8.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}
.whatnext__right {
  display: flex;
  justify-content: end;
}
.whatnext__boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16em;
}
.whatnext__box {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1em;
  width: 300px;
  margin-bottom: 4em;
  background-color: #cae9ff;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  border-radius: 20px;
}
.whatnext__box-title {
  margin-top: 2.5em;
  margin-bottom: 1em;
  text-transform: uppercase;
  color: #1b4965;
  text-decoration: none;
  text-align: center;
}
.whatnext__box-circle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 17px -7px rgb(66, 68, 90);
  background-color: #efefef;
}
.whatnext__box-icon {
  color: #1b4965;
  font-size: 40px;
  text-align: center;
  line-height: 70px;
  transform: translate(30%, 30%);
}
.whatnext__box-icon-location {
  transform: translate(70%, 30%);
}
.whatnext__box-p {
  margin-bottom: -1em;
  text-align: center;
}
.whatnext__box-txt {
  border: none;
  background-color: #bee9e8;
  color: #1b4965;
  font-weight: bold;
  cursor: pointer;
}
.whatnext__box-btn {
  margin: 0 auto;
  margin-top: 3em;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  border: none;
  background-color: #bee9e8;
  border-radius: 50px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.481);
  z-index: 101;
}
.whatnext__box-right {
  margin-left: 3.5em;
}
.whatnext__title {
  margin-top: 2em;
  margin-bottom: 2.5em;
  font-size: 2.6rem;
  font-weight: bold;
  color: #1b4965;
  text-align: center;
  text-transform: uppercase;
}

.first__row {
  display: none;
}

.second__row {
  display: none;
}

.offer {
  position: relative;
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: contain;
  background-repeat: repeat;
}
.offer__title {
  margin-top: 2em;
  margin-bottom: 2.5em;
  font-size: 2.6rem;
  font-weight: bold;
  color: #1b4965;
  text-align: center;
  text-transform: uppercase;
}
.offer__boxes {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 1em;
  padding-bottom: 2em;
}
.offer__box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em;
  margin-bottom: 2em;
  width: 150px;
  height: 150px;
  border: none;
  background-color: #efefef;
  border-radius: 20px;
  text-decoration: none;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  cursor: pointer;
  z-index: 101;
}
.offer__box:last-child {
  padding-bottom: 6em;
}
.offer__box-title {
  margin-top: 7.5em;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1em;
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #1b4965;
}
.offer__box-title--zw {
  margin-top: 7em;
}
.offer__box-img {
  position: absolute;
  width: 140px;
  top: 0;
  left: 0;
}

.aboutus {
  position: relative;
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: contain;
  background-repeat: repeat;
}
.aboutus__boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.aboutus__box {
  position: relative;
  padding: 1em 2em;
  margin-right: 1em;
  margin-left: 1em;
  margin-bottom: 3em;
  width: 330px;
  height: 400px;
  background-color: #eceaea;
  border-radius: 20px;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
}
.aboutus__box-description1 {
  text-align: justify;
}
.aboutus__box-circle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 0.659);
}
.aboutus__box-name {
  margin-top: 1.5em;
  margin-bottom: 1em;
  color: #1b4965;
  font-size: 2rem;
  text-align: center;
}
.aboutus__box-title {
  color: #7e7e7e;
  font-size: 1.6rem;
}
.aboutus__box-clinic {
  padding-top: 4em;
  width: 400px;
  height: min-content;
}
.aboutus-icon {
  transform: translate(45%, 30%);
  color: #1b4965;
}
.aboutus__photos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2em;
}
.aboutus__photo {
  width: 350px;
  margin-bottom: 1.5em;
  margin-right: 1em;
  margin-left: 1em;
  border-radius: 20px;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  z-index: 101;
}

.singleoffer {
  position: relative;
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: contain;
  background-repeat: repeat;
}
.singleoffer__title {
  color: #1b4965;
}
.singleoffer__boxes {
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleoffer__list {
  margin-top: 2em;
  padding: 1em;
  list-style-type: circle;
}
.singleoffer__item {
  padding: 0.5em;
  padding-top: 0em;
  text-transform: uppercase;
  color: #1b4965;
  font-size: 1.8rem;
}
.singleoffer__box {
  position: relative;
  padding: 1em 2em;
  margin-bottom: 6em;
  width: 350px;
  background-color: #eceaea;
  border-radius: 20px;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
  z-index: 101;
}
.singleoffer__box-circle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: #f6f3f3;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 0.659);
}
.singleoffer__box-img {
  width: 100px;
}

.tutorial {
  position: relative;
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: contain;
  background-repeat: repeat;
}
.tutorial__boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 6em;
}
.tutorial__box {
  position: relative;
  padding: 1em 2em;
  margin-right: 1em;
  margin-left: 1em;
  margin-bottom: 3em;
  width: 330px;
  background-color: #eceaea;
  border-radius: 20px;
  box-shadow: 3px 7px 14px -3px rgb(66, 68, 90);
}
.tutorial__box-circle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  box-shadow: 3px 7px 14px -3px rgba(66, 68, 90, 0.659);
}
.tutorial__box-name {
  margin-top: 2em;
  margin-bottom: 1em;
  color: #1b4965;
  font-size: 2rem;
  text-align: center;
}
.tutorial-icon {
  transform: translate(45%, 30%);
  color: #1b4965;
}
.tutorial__list {
  list-style-type: circle;
}
.tutorial__item {
  padding: 0.5em;
  padding-top: 0em;
}

.reservations {
  background-image: url("/dist/img/mobile-paws-bg-5.png");
  background-size: contain;
  background-repeat: repeat;
}

.hours {
  display: flex;
  justify-content: center;
  align-items: center;
}

.harmonogram {
  border-collapse: collapse;
  margin: 2.5em 0;
  font-size: 0.9em;
  min-height: 410px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.481);
  overflow: hidden;
  z-index: 101;
}

.harmonogram thead tr {
  background-color: #cae9ff;
  color: #1b4965;
  text-align: left;
  font-weight: bold;
}

.harmonogram th,
.harmonogram td {
  padding: 0.3em;
}

.harmonogram tbody tr {
  border-bottom: 1px solid #ddd;
}

.harmonogram tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.harmonogram tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.harmonogram tbody tr:last-of-type {
  border-bottom: 3px solid #cae9ff;
}

.page__title {
  margin-top: 2em;
  margin-bottom: 2.5em;
  font-size: 2.6rem;
  font-weight: bold;
  color: #1b4965;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .header__btn {
    top: 32vh;
    padding: 1.1em 2.2em;
  }
  .page__title {
    font-size: 3.6rem;
  }
  .tutorial__box {
    height: 730px;
  }
  .singleoffer {
    background-image: url("/dist/img/desktop-paws-bg-4.png");
    background-size: cover;
  }
  .singleoffer__box {
    width: 500px;
    margin-bottom: 8em;
  }
  .header__title {
    font-size: 2.2rem;
  }
  .header__slogan {
    font-size: 3.6rem;
  }
  .header__img {
    height: 60vh;
  }
  .burger-btn {
    right: 25px;
  }
  .about__btn {
    padding: 1em 2em;
    margin-bottom: 8em;
  }
  .contact__title {
    margin-top: 1em;
  }
  .contact__boxes {
    margin-bottom: 2em;
  }
  .whatnext__box {
    width: 400px;
  }
  .whatnext__box-p {
    padding: 0 2em;
  }
}
@media (min-width: 830px) {
  .offer__boxes-row {
    margin: 0 auto;
    width: 800px;
  }
  .first__row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
  .second__row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-bottom: 8em;
  }
  .offer {
    background-image: url("/dist/img/desktop-paws-bg-4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .offer__boxes {
    display: none;
  }
  .aboutus__photos {
    margin-bottom: 8em;
  }
}
@media (min-width: 992px) {
  .singleoffer__box {
    width: 600px;
    margin-bottom: 10em;
  }
  .callus {
    background-image: url("/dist/img/desktop-paws-bg-4.png");
  }
  .footer__boxes {
    display: flex;
    justify-content: space-between;
  }
  .footer__box {
    margin: 0 2em;
    width: 25%;
  }
  .footer__box-mobile {
    display: none;
  }
  .footer__box-desktop {
    display: block;
  }
  .footer__box:first-child {
    width: 50%;
  }
  .header__img {
    height: 75vh;
    background-size: cover;
  }
  .header__slogan {
    top: 25vh;
    font-size: 4.2rem;
  }
  .header__title {
    font-size: 3.6rem;
    top: 20vh;
  }
  .header__btn {
    top: 43vh;
  }
  .second__row {
    margin-bottom: 10em;
  }
  .aboutus__photo {
    width: 400px;
  }
  .aboutus__photos {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    margin-bottom: 9em;
  }
  .aboutus__box {
    width: 600px;
  }
  .nav__img {
    margin-left: 1em;
    transform: translate(0%);
  }
  .nav__right {
    display: flex;
  }
  .nav__el {
    display: inline;
    margin-right: 1em;
    font-size: 1.4rem;
  }
  .nav__el:last-child {
    margin-right: 2em;
  }
  .nav__el a {
    color: #1b4965;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
  }
  .nav__li {
    color: #1b4965;
    text-decoration: none;
  }
  .nav__btn {
    display: inline-block;
    margin-right: 2.5em;
  }
  .burger-btn {
    display: none;
  }
  .waves {
    margin-bottom: -1.1em;
  }
  .about {
    background-image: url("/dist/img/desktop-paws-bg-4.png");
    background-size: cover;
  }
  .about__title {
    font-size: 3.6rem;
  }
  .about__box {
    margin: 1em;
    width: 210px;
  }
  .about__boxes {
    margin-bottom: 2em;
  }
  .about__btn {
    font-size: 1.3rem;
    padding: 1em 2em;
    margin-bottom: 10em;
  }
  .contact {
    background-image: url("/dist/img/desktop-route-7.png");
  }
  .contact__title {
    margin-top: 0em;
    font-size: 3.6rem;
  }
  .contact__box {
    margin-bottom: 8em;
  }
  .whatnext {
    background-image: url("/dist/img/desktop-wn-6.png");
  }
  .whatnext__title {
    font-size: 3.6rem;
  }
  .whatnext__boxes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .whatnext__box {
    margin-left: 6em;
    width: 400px;
  }
  .whatnext__box-right {
    margin-right: 2em;
    justify-self: flex-end;
  }
  .whatnext__box-btn {
    margin-bottom: 2em;
    padding: 1em 2em;
  }
  .reservations {
    background-image: url("/dist/img/desktop-paws-bg-4.png");
    background-size: cover;
    background-repeat: repeat;
  }
  .hours {
    margin-bottom: 12em;
    background-image: url("/dist/img/desktop-paws-bg-4.png");
    background-size: cover;
    background-repeat: repeat;
  }
  .harmonogram {
    border-collapse: collapse;
    margin: 2.5em 1em;
    font-size: 1em;
    min-height: 400px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.481);
    overflow: hidden;
  }
  .harmonogram thead tr {
    background-color: #cae9ff;
    color: #1b4965;
    text-align: left;
    font-weight: bold;
  }
  .harmonogram th,
  .harmonogram td {
    padding: 1em;
  }
}
@media (min-width: 1200px) {
  .singleoffer__box {
    width: 800px;
    margin-bottom: 16em;
  }
  .tutorial__boxes {
    margin-bottom: 12em;
  }
  .second__row {
    margin-bottom: 16em;
  }
  .aboutus__photos {
    margin-bottom: 12em;
  }
  .page__title {
    font-size: 3.6rem;
  }
  .nav__el {
    font-size: 1.6rem;
  }
  .about__box {
    width: 250px;
  }
  .about__boxes {
    margin-bottom: 2em;
  }
  .about__btn {
    margin-bottom: 20em;
  }
  .header__img {
    height: 80vh;
  }
  .whatnext__box {
    margin-bottom: 6em;
  }
  .whatnext__box-right {
    margin-right: 10em;
  }
  .contact__boxes {
    margin-bottom: 10em;
  }
}